.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #1565c0;
}

.landing-icon {
  z-index: 1000;
	position: absolute;
	padding-left: 10px;
	padding-right: 10px;
	left: 50%;
    top: 0;
    transform:translate(-50%,-50%);
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
}

.brand-strong {
  font-weight: 700;
  font-size: 1.5em;
}
.brand-thin {
  font-weight: 100;
  font-size: 1.1em;
}

.fw300 {
  font-weight: 300;
}
.fw100 {
  font-weight: 100;
}

.mainbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.welcomebox1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
background: linear-gradient(217deg, #4D71E5, rgba(255,0,0,0) 70.71%),
linear-gradient(127deg, #4D71E5, rgba(0,255,0,0) 70.71%),
linear-gradient(336deg, #4D71E5, rgba(0,0,255,0) 70.71%);

}

.welcomebox2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0.1turn,#3887E7, #4D71E5, #C572DB, #E79EC0);

}


.welcomebox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('/src/assets/images/welcome.png');
}

.lang-flag {
  width: 20px;
  height: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
