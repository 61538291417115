
@import 'flag-icons/css/flag-icons.min.css';
@import 'react-pro-sidebar/dist/css/styles.css';
/*
html {
   font-size: 87.5%;   87.5% of 16px = 14px 
}
*/
body {
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: 'subpixel-antialiased';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
}


.container-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.app-link {
  font-size: .95em;
}
.app-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pdfdoc {
  width: '200px';
  height: '600px';
}